import type { JSX } from 'solid-js'

import styles from './NavBar.module.css'

export const NavBar = (props: JSX.IntrinsicElements['nav']) => {
  return (
    <nav
      {...props}
      classList={{ [styles.NavBar!]: true, ...props.classList }}
    >
      {props.children}
    </nav>
  )
}
