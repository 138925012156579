import type { IconTypes } from 'solid-icons'
import { createMemo, type JSX } from 'solid-js'

import { useI18n } from '#/i18n/I18nProvider'

import { Link } from '../Link'
import styles from './NavBarLink.module.css'

export const NavBarLink = (props: {
  children: JSX.Element
  href: string
  icon: IconTypes
}) => {
  const { pathnameWithLanguage } = useI18n()

  const currentPathName = createMemo(() => pathnameWithLanguage({}))
  return (
    <Link
      class={styles.NavBarLink}
      href={props.href}
      data-active={
        currentPathName() === pathnameWithLanguage({ pathname: props.href })
          ? ''
          : undefined
      }
    >
      <div class={styles.NavBarLinkWrapper}>
        <props.icon class={styles.Icon} />

        <div>{props.children}</div>
      </div>
    </Link>
  )
}
